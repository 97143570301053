export const QUICKSTART_DOCTOR = '/img/quick-start-doctor.png';
export const QUICKSTART_SCHOOL = '/img/quick-start-school.png';
export const QUICKSTART_HOSPITAL = '/img/quick-start-hospital.png';
export const QUICKSTART_BG = '/img/quick-start-bg.svg';
export const GIFT_BOX_IMG = '/box1.svg';
export const DEFAULT_AVT_IMG = '/img/default-avatar.svg';
export const DEFAULT_FLAT_AVT_IMG = '/img/flat-default-avatar.png';
export const NFC_SCAN_IMG = '/img/nfc-scan.png';
export const NFC_SCAN_2_IMG = '/img/nfc-scan-2.png';
export const NFC_SCAN_3_IMG = '/img/nfc-scan-3.png';
export const DEFAULT_GROUP_COVER = '/img/default-group-cover.svg';

export const ERROR_LOGO = '/red-failed.png';
export const SUCCESS_LOGO = '/pinkcheck.svg';

export const UploadImgIcon = '/icons/upload-img-icon.svg';
export const UploadNotImgIcon = '/icons/upload-not-img-icon.svg';
export const UploadErrorIcon = '/icons/upload-error-icon.svg';
export const UploadSuccessIcon = '/icons/upload-success-icon.svg';

export const UploadIcon = '/icons/upload-icon.svg';
export const FolderIcon = '/icons/folder-icon.svg';
export const GroupsIcon = '/icons/groups-icon.svg';
export const MoreActions = '/icons/moreAction.svg';
export const ExitNoBgIcon = '/icons/exit-no-bg.svg';

// Default Thumbnails
export const DefaultThumbnail = '/img/DefaultThumbnail.png';
export const LessonCourseDefaultThumbnail =
  '/img/lesson-course-default-thumbnail.svg';
export const QuizDefaultThumbnail = '/img/quiz-default-thumbnail.svg';
export const H5PDefaultThumbnail = '/img/H5PDefaultThumbnail.jpg';
export const LockedPostDefaultThumbnail = '/img/DefaultPostThumbnail.png';
export const NewNotiDoccenIcon = '/icons/doccen-logo.svg';

// course and lesson icon
export const ClockIcon = '/icons/course-tab-icons/clock.svg';
export const NoteIcon = '/icons/course-tab-icons/note.svg';
export const PlayButtonIcon = '/icons/course-tab-icons/play-button.svg';
export const PlusButtonIcon = '/icons/course-tab-icons/plus-button.svg';

// SideNav và Footer icon bắt đầu từ đây

export const SETTING_ICON = '/icons/navIcons/setting.svg';
export const SETTING_ICON_SELECTED = '/icons/navIcons/setting-selected.svg';

export const BELL_ICON = '/icons/navIcons/bell.svg';
export const BELL_SELECTED_ICON = '/icons/navIcons/bell_selected.svg';
export const BELL_HAVE_NOTI_ICON = '/icons/navIcons/bell_have_noti.svg';
export const BELL_ICON_SELECTED = '/icons/navIcons/bell_selected.svg';

export const CLOUD_ICON = '/icons/navIcons/cloud.svg';

// Doccen Logos
export const DoccenIconNoColor = '/img/doccen-icon-no-color.svg';
export const DoccenIconNoColorGrayBg = '/img/doccen-icon-no-color-gray-bg.svg';
export const DOCCEN_LOGO_ICON = '/icons/navIcons/doccenLogo.svg';
export const DOCCEN_TYPO_ICON = '/icons/navIcons/doccenTypo.svg';
export const DOCCEN_WHITE = '/img/doccen-white.png';
export const DOCCEN_AI_LOGO_ICON = '/img/ai/doccen-ai-logo.svg';
export const DOCCEN_AI_TYPO_ICON = '/img/ai/doccen-ai-typo.svg';
export const DOCCEN_TYPO = '/img/ai/doccen-typo.svg';
export const DOCCEN_AI_TYPO_BETA = '/img/ai/doccen-ai-typo-beta.svg';

// general icon import

export const TrashIcon = '/icons/general-icons/trash.svg';
export const EditIcon = '/icons/general-icons/edit.svg';

// comment icon

export const TrashNoColorIcon = '/icons/comment/trash.svg';
export const TrashIconHover = '/icons/comment/hover-trash.svg';
export const PinIcon = '/icons/comment/pin.svg';
export const WhitePinIcon = '/icons/comment/white-pin.svg';
export const PinIconHover = '/icons/comment/hover-pin.svg';
export const EditComment = '/icons/comment/edit.svg';
export const EditCommentHover = '/icons/comment/hover-edit.svg';

// noti icon

export const WaveHandIcon = '/icons/wave-hand.svg';
export const WhiteLockIcon = '/icons/lock-white.svg';

export const ZaloIcon = '/icons/Zalo.svg';

export const State404Icon = '/icons/error-state/404.svg';
export const State403Icon = '/icons/error-state/403.svg';

export const ConfirmModalQR = '/icons/CaMauConfirmModalQRVer2.jpg';

export const CertificateCardCPE = '/img/certificate-card-cpe.png';
export const CertificateCardCME = '/img/certificate-card-cme.png';
export const IntroductionBannerCPE = '/img/CPE_introduction_banner.png';
export const IntroductionBannerCME = '/img/CME_introduction_banner.png';

export const DemoBusinessCourseThumbnail = '/img/bussiness-course.png';
export const DemoEliteTeacher = '/img/Teacher.png';
export const DemoVideoThumbail = '/img/Video.png';

export const FullMobileFrame = '/img/device-iphone-x.png';

export const DemoHomepageIntroductionBanner = '/banner/yds.png';
export const DemoHomepageEventBanner = '/banner/banner.png';
export const DemoInformationIntroductionBanner = '/banner/large_banner.png';
export const DemoInformationSection3Banner = '/banner/Screen.png';

export const DemoDegreeIntroductionImg = '/img/degree-introduction-img.png';

export const DemoMemberIntroductionImg = '/img/member-tab-banner.png';

export const DemoCourseIntroductioBanner = '/img/course-section-demo-cover.png';

export const DemoHighlightCourseThumbnail =
  '/img/highlight-course-thumbnail.png';

export const CourseRoadmapRegisterFormCover =
  '/img/course_roadmap/register_form_cover.png';

export const CourseRoadmapBannerMobile =
  '/img/course_roadmap/banner_mobile.png';
export const CourseRoadmapBannerDesktop =
  '/img/course_roadmap/banner_desktop.png';

export const MarketingPharmarcyCourseThumbnail = {
  thumbnail: '/img/course_roadmap/pharmarcy_marketing/thumbnail.png',
  '1A': '/img/course_roadmap/pharmarcy_marketing/pharmarcy_marketing_1A.png',
  '1B': '/img/course_roadmap/pharmarcy_marketing/pharmarcy_marketing_1B.png',
  '2A': '/img/course_roadmap/pharmarcy_marketing/pharmarcy_marketing_2A.png',
  '2B': '/img/course_roadmap/pharmarcy_marketing/pharmarcy_marketing_2B.png',
  '2C': '/img/course_roadmap/pharmarcy_marketing/pharmarcy_marketing_2C.png',
  '2D': '/img/course_roadmap/pharmarcy_marketing/pharmarcy_marketing_2D.png',
  '3A': '/img/course_roadmap/pharmarcy_marketing/pharmarcy_marketing_3A.png',
  '3B': '/img/course_roadmap/pharmarcy_marketing/pharmarcy_marketing_3B.png',
  '3C': '/img/course_roadmap/pharmarcy_marketing/pharmarcy_marketing_3C.png',
  '3D': '/img/course_roadmap/pharmarcy_marketing/pharmarcy_marketing_3D.png',
  '3E': '/img/course_roadmap/pharmarcy_marketing/pharmarcy_marketing_3E.png',
  '4A': '/img/course_roadmap/pharmarcy_marketing/pharmarcy_marketing_4A.png',
  '4B': '/img/course_roadmap/pharmarcy_marketing/pharmarcy_marketing_4B.png',
};

export const MedicalCourseCourseThumbnail = {
  thumbnail: '/img/course_roadmap/medical_course/thumbnail.png',
  '1A': '/img/course_roadmap/medical_course/medical_course_1A.png',
  '1B': '/img/course_roadmap/medical_course/medical_course_1B.png',
  '2A': '/img/course_roadmap/medical_course/medical_course_2A.png',
  '2B': '/img/course_roadmap/medical_course/medical_course_2B.png',
  '3A': '/img/course_roadmap/medical_course/medical_course_3A.png',
  '3B': '/img/course_roadmap/medical_course/medical_course_3B.png',
  '3C': '/img/course_roadmap/medical_course/medical_course_3C.png',
  '3D': '/img/course_roadmap/medical_course/medical_course_3D.png',
  '4A': '/img/course_roadmap/medical_course/medical_course_4A.png',
  '4B': '/img/course_roadmap/medical_course/medical_course_4B.png',
  '4C': '/img/course_roadmap/medical_course/medical_course_4C.png',
  '4D': '/img/course_roadmap/medical_course/medical_course_4D.png',
};

export const ParaclinicalInternalMedicineThumbnail = {
  thumbnail: '/img/course_roadmap/paraclinical_internal_medicine/thumbnail.png',
  '1A': '/img/course_roadmap/paraclinical_internal_medicine/1A.png',
  '1B': '/img/course_roadmap/paraclinical_internal_medicine/1B.png',
  '1C': '/img/course_roadmap/paraclinical_internal_medicine/1C.png',
  '1D': '/img/course_roadmap/paraclinical_internal_medicine/1D.png',
  '2A': '/img/course_roadmap/paraclinical_internal_medicine/2A.png',
  '2B': '/img/course_roadmap/paraclinical_internal_medicine/2B.png',
  '2C': '/img/course_roadmap/paraclinical_internal_medicine/2C.png',
  '2D': '/img/course_roadmap/paraclinical_internal_medicine/2D.png',
  '3A': '/img/course_roadmap/paraclinical_internal_medicine/3A.png',
  '3B': '/img/course_roadmap/paraclinical_internal_medicine/3B.png',
  '3C': '/img/course_roadmap/paraclinical_internal_medicine/3C.png',
  '4A': '/img/course_roadmap/paraclinical_internal_medicine/4A.png',
  '4B': '/img/course_roadmap/paraclinical_internal_medicine/4B.png',
  '4C': '/img/course_roadmap/paraclinical_internal_medicine/4C.png',
  '5A': '/img/course_roadmap/paraclinical_internal_medicine/5A.png',
  '5B': '/img/course_roadmap/paraclinical_internal_medicine/5B.png',
  '5C': '/img/course_roadmap/paraclinical_internal_medicine/5C.png',
  '5D': '/img/course_roadmap/paraclinical_internal_medicine/5D.png',
};

export const PharmacyPharmacistsThumbnail = {
  thumbnail: '/img/course_roadmap/pharmacy_pharmacists/thumbnail.png',
  '1A': '/img/course_roadmap/pharmacy_pharmacists/1A.png',
  '1B': '/img/course_roadmap/pharmacy_pharmacists/1B.png',
  '2A': '/img/course_roadmap/pharmacy_pharmacists/2A.png',
  '2B': '/img/course_roadmap/pharmacy_pharmacists/2B.png',
  '2C': '/img/course_roadmap/pharmacy_pharmacists/2C.png',
  '2D': '/img/course_roadmap/pharmacy_pharmacists/2D.png',
  '2E': '/img/course_roadmap/pharmacy_pharmacists/2E.png',
  '2F': '/img/course_roadmap/pharmacy_pharmacists/2F.png',
  '2G': '/img/course_roadmap/pharmacy_pharmacists/2G.png',
  '2H': '/img/course_roadmap/pharmacy_pharmacists/2H.png',
  '2I': '/img/course_roadmap/pharmacy_pharmacists/2I.png',
  '3A': '/img/course_roadmap/pharmacy_pharmacists/3A.png',
  '3B': '/img/course_roadmap/pharmacy_pharmacists/3B.png',
  '3C': '/img/course_roadmap/pharmacy_pharmacists/3C.png',
  '3D': '/img/course_roadmap/pharmacy_pharmacists/3D.png',
  '3E': '/img/course_roadmap/pharmacy_pharmacists/3E.png',
  '3F': '/img/course_roadmap/pharmacy_pharmacists/3F.png',
  '3G': '/img/course_roadmap/pharmacy_pharmacists/3G.png',
  '3H': '/img/course_roadmap/pharmacy_pharmacists/3H.png',
  '4A': '/img/course_roadmap/pharmacy_pharmacists/4A.png',
  '4B': '/img/course_roadmap/pharmacy_pharmacists/4B.png',
};

export const LogoDoccenWhiteMedium = '/img/logo/doccen_white_medium.png';

export const WatermarkThumbnail = '/img/watermark-thumbnail-1.png';

export const WatermarkThumbnail2 = '/img/watermark-thumbnail-2.png';
export const WatermarkThumbnail3 = '/img/watermark-thumbnail-3.png';

export const ZaloLogo = '/img/zalo-logo.png';

export const MuiltiCirclesBg = '/img/multi-circles-bg.webp';
export const NurseImage2 = '/img/nurse-image-2.webp';
export const NurseImage3 = '/img/nurse-image-3.webp';
export const EmptyBox = '/img/empty-box.png';
export const EmptyPlaylist = '/img/empty-playlist.png';
export const DefaultThumbnailLiveStream =
  '/img/default-thumnail-livestream.png';
export const AIThumnailSEO =
  'https://res.cloudinary.com/shopdev2/image/upload/v1724321543/ygjngskrytnxbj83qrgm.png';

export const OutOfTime = '/outoftime.svg';
export const AIEmptyFolder = '/img/ai/entypo_folder.webp';
export const EmptyChatSVG = '/img/empty-chat.svg';
export const PDFEmptySVG = '/img/pdf-empty.svg';
export const ChatEmptySVG = '/img/chat-empty.svg';
export const AIEmptyFiles = '/img/ai/empty-files.svg';

export const SubscriptionConfirmPolicy =
  '/img/subscription-confirm-policy.webp';

export const ExpiredIllustration = '/img/expired.webp';
export const SubscriptionDefaultThumbnail =
  '/img/default-subscription-thumbnail.webp';
