import { ButtonEmphasis, ButtonMode, ButtonSize } from './types';

const BUTTON_SIZE_CLASSES = {
  small: 'h-[32px]',
  medium: 'h-[40px]',
  large: 'h-[48px]',
};

const BUTTON_FRONT_SIZE_CLASSES = {
  small: 'text-[12px]',
  medium: 'text-[14px]',
  large: 'text-[16px]',
};

const BUTTON_PADDING_CLASSES = {
  small: 'px-[9px] py-[7px] leading-[16px]',
  medium: 'px-[15px] py-[7px]',
  large: 'px-[15px] py-[7px]',
};

const BUTTON_EMPHASIS_CLASSES = {
  high: 'disabled:text-Text/Lightbackground/Disabled bg-[#009CE0] text-white hover:bg-Primary/Hover focus-visible:border-white focus-visible:bg-Primary/Main focus-visible:shadow-button active:bg-Primary/Pressed disabled:bg-Action/DisabledBackground',
  medium:
    'disabled:text-Text/Lightbackground/Disabled border-[#D3DFE7] bg-white text-Primary/Main hover:bg-white focus-visible:shadow-button_medium active:!bg-Primary/Pressed/Low/Med disabled:border-Other/Outlinedborder-stroke disabled:bg-Action/DisabledBackground xl:hover:bg-Primary/Other/Hover',
  low: 'bg-transparent text-[#009CE0] hover:bg-transparent focus-visible:shadow-button_medium active:!bg-Primary/Pressed/Low/Med disabled:bg-Action/DisabledBackground xl:hover:bg-Primary/Other/Hover',
};

const ICON_BUTTON_PADDING_CLASSES = {
  small: 'p-2 w-8 h-8 w-8 min-w-[unset]',
  medium: 'px-[10px] py-2 h-10 w-10 min-w-[unset]',
  large: '!px-3 py-2 h-12 w-12 min-w-[unset]',
};

export const getButtonClasses = (
  size: ButtonSize,
  emphasis: ButtonEmphasis,
  link: boolean,
  underline: boolean,
  mode: ButtonMode,
  disabled: boolean | undefined = false
): string => {
  const isIconOnly = mode === 'icon-only';

  if (link) {
    return [
      'text-[#009CE0] h-6 px-4 focus-visible:shadow-button_medium rounded-[100px]',
      underline && 'underline',
    ].join(' ');
  }

  return [
    'relative rounded-[100px] font-medium flex items-center justify-center border border-transparent border-solid active:shadow-none w-fit focus-visible:outline-none',
    BUTTON_SIZE_CLASSES[size],
    BUTTON_FRONT_SIZE_CLASSES[size],
    BUTTON_PADDING_CLASSES[size],
    BUTTON_EMPHASIS_CLASSES[emphasis],
    isIconOnly && ICON_BUTTON_PADDING_CLASSES[size],
    disabled ? 'pointer-events-none' : '',
  ].join(' ');
};
