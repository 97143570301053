import { getSocialIconPath } from './helper';
import { SocialIconProps } from './types';

const SocialIcon = (props: SocialIconProps) => {
  const {
    iconName,
    size = 24,
    className,
    viewBox = '0 0 24 24',
    ...restProps
  } = props;
  const path = getSocialIconPath(iconName);
  return (
    <span className={className}>
      <svg width={size} height={size} viewBox={viewBox} {...restProps}>
        {path}
      </svg>
    </span>
  );
};

export default SocialIcon;
