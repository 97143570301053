import { forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';
import { getTypographyClass } from './helper';
import { TypographyProps } from './types';

const Typography = forwardRef<HTMLParagraphElement, TypographyProps>(
  function Typography(props: TypographyProps, ref) {
    const { variant, className, ...restProps } = props;
    const classes = getTypographyClass(variant);
    return (
      <p {...restProps} ref={ref} className={twMerge(classes, className)} />
    );
  }
);

export default Typography;
