import { twMerge } from 'tailwind-merge';
import { getGenericIconPath } from './helper';

const GenericIcon = (props: any) => {
  const {
    iconName,
    variant = 'outline',
    size = 24,
    className,
    viewBox = '0 0 24 24',
    ...restProps
  } = props;
  const path = getGenericIconPath(iconName, variant);
  return (
    <span className={twMerge(className, 'text-inherit')}>
      <svg
        width={size}
        height={size}
        viewBox={viewBox}
        fill={variant === 'outline' ? 'none' : 'currentColor'}
        {...restProps}
      >
        {path}
      </svg>
    </span>
  );
};

export default GenericIcon;
