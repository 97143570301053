import {
  ILastestSubscriptionPlanResponse,
  ISubscriptionPlanShortInformation,
  ISubscriptionPlansResponse,
} from '@/interfaces/subscription.interface';
import { tmsHTTPRequest } from 'services/axios/httpRequest';

const preEndpoint = '/v1/subscriptions';

export const getSubscriptionsPlans = () =>
  tmsHTTPRequest.get<ISubscriptionPlansResponse>(`${preEndpoint}/plans`);

export const getLatestSubscription = () =>
  tmsHTTPRequest.get<ILastestSubscriptionPlanResponse>(`${preEndpoint}/latest`);

export const getDetailSubscriptionPlan = (planId: string) =>
  tmsHTTPRequest.get<ISubscriptionPlanShortInformation>(
    `${preEndpoint}/plans/${planId}`
  );

export const patchReminderSubscription = ({
  isReminded,
  subscriptionId,
}: {
  subscriptionId: string;
  isReminded: boolean;
}) => tmsHTTPRequest.patch(`${preEndpoint}/${subscriptionId}`, { isReminded });
