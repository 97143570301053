import { SUBSCRIPTIONS_PLANS } from '@/constant/QueryKeyName';
import { ISubscriptionPlanShortInformation } from '@/interfaces/subscription.interface';
import { getDetailSubscriptionPlan } from 'apis/tms/subscription.api';
import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';

const useGetDetailSubscriptionPlan = ({
  planId,
}: {
  planId: string;

  opt?: Omit<
    UseQueryOptions<ISubscriptionPlanShortInformation, AxiosError>,
    'queryKey' | 'queryFn'
  >;
}) => {
  const queryReturn = useQuery<ISubscriptionPlanShortInformation, AxiosError>(
    [SUBSCRIPTIONS_PLANS, planId],
    () => getDetailSubscriptionPlan(planId),
    {
      enabled: !!planId,
    }
  );

  return queryReturn;
};

export default useGetDetailSubscriptionPlan;
