import Link from 'next/link';
import { forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';
import GenericIcon from '../Icon/GenericIcon';
import { getButtonClasses } from './helper';
import { ButtonProps } from './types';

const Button = forwardRef<HTMLButtonElement, ButtonProps>(function Foo(
  props,
  ref
) {
  const {
    type = 'button',
    children,
    mode = 'text-only',
    emphasis = 'medium',
    size = 'medium',
    className,
    link = false,
    href,
    underline = false,
    icon,
    startIcon,
    endIcon,
    ...restProps
  } = props;

  const isIconOnly = mode === 'icon-only';

  const finalClassName = twMerge(
    getButtonClasses(size, emphasis, link, underline, mode, props.disabled),
    className
  );

  const finalSizeIcon = size === 'small' ? 16 : size === 'medium' ? 20 : 24;

  const renderContent = () => {
    if (link && href) {
      return (
        <>
          {isIconOnly ? (
            <button
              {...restProps}
              ref={ref}
              type={type}
              tabIndex={0}
              className={finalClassName}
            >
              {isIconOnly && icon && (
                <GenericIcon
                  iconName={icon.name}
                  variant={icon?.variant || 'outline'}
                  width={finalSizeIcon}
                  height={finalSizeIcon}
                />
              )}
            </button>
          ) : (
            <Link href={href} passHref>
              <a className={`${finalClassName} flex items-center`}>
                <div className="flex items-center">
                  {children}
                  {endIcon}
                </div>
              </a>
            </Link>
          )}
        </>
      );
    }

    return (
      <button
        {...restProps}
        ref={ref}
        type={type}
        tabIndex={0}
        className={finalClassName}
      >
        {isIconOnly && icon ? (
          <GenericIcon
            iconName={icon.name}
            variant={icon?.variant || 'outline'}
            width={finalSizeIcon}
            height={finalSizeIcon}
            viewBox={icon?.viewBox}
          />
        ) : (
          <>
            {startIcon && (
              <GenericIcon
                iconName={startIcon.name}
                variant={startIcon?.variant || 'outline'}
                width={finalSizeIcon}
                height={finalSizeIcon}
                viewBox={startIcon?.viewBox}
                className={size === 'small' ? 'mr-1' : 'mr-2'}
              />
            )}
            {children}
            {endIcon && (
              <GenericIcon
                iconName={endIcon.name}
                variant={endIcon?.variant || 'outline'}
                width={finalSizeIcon}
                height={finalSizeIcon}
                viewBox={endIcon?.viewBox}
                className={size === 'small' ? 'ml-1' : 'ml-2'}
              />
            )}
          </>
        )}
      </button>
    );
  };

  return renderContent();
});

export default Button;
