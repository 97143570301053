import { useScreen } from '@/hooks/useScreen';
import useToggle from '@/hooks/useToggle';
import { Button, ButtonProps } from 'design-system/components/Button';
import dynamic from 'next/dynamic';
import { twMerge } from 'tailwind-merge';

const SubscriptionModal = dynamic(
  () => import('../elements/subscription/SubscriptionModal')
);

type UpgradeAccountButtonProps = ButtonProps & {
  ignoreMobile?: boolean;
};

const UpgradeAccountButton = (props: UpgradeAccountButtonProps) => {
  const { className, children, ignoreMobile = false, ...restProps } = props;

  const [open, toggle] = useToggle();

  const { isMobile } = useScreen();
  const finalClassName = twMerge(
    'bg-Other/Upgradeaccount text-black',
    className
  );

  return (
    <>
      {isMobile && !ignoreMobile ? (
        <Button
          {...restProps}
          onClick={toggle}
          mode="icon-only"
          icon={
            restProps.icon ?? {
              name: 'diamond',
              variant: 'solid',
              viewBox: '0 0 20 20',
            }
          }
          className={finalClassName}
        />
      ) : (
        <Button
          {...restProps}
          startIcon={
            restProps.startIcon ?? {
              name: 'diamond',
              variant: 'solid',
              viewBox: '0 0 20 20',
            }
          }
          onClick={toggle}
          className={finalClassName}
        >
          <p>{children}</p>
        </Button>
      )}

      {open && <SubscriptionModal open={open} toggle={toggle} />}
    </>
  );
};

export default UpgradeAccountButton;
