import { ZaloLogo } from '@/constant/imgImport';

export const SOCIAL_ICONS = {
  word: [
    <path
      key={0}
      d="M13.902 1.74225V3.651C16.452 3.66525 19.242 3.62475 21.7927 3.66525C21.8827 3.6496 21.9751 3.65516 22.0625 3.68147C22.15 3.70777 22.2301 3.75411 22.2965 3.8168C22.3629 3.87949 22.4138 3.9568 22.4451 4.04259C22.4763 4.12838 22.4872 4.22028 22.4767 4.311C22.5173 9.0105 22.467 13.9785 22.5007 18.6787C22.4858 18.9787 22.5075 19.5173 22.461 19.8165C22.4018 20.1982 22.2315 20.2717 21.8483 20.3235C21.6338 20.3527 21.2753 20.349 21.0645 20.3587C18.9705 20.3482 16.878 20.3505 14.784 20.3512H13.9028V22.2615L1.5 20.3497V3.65475L13.902 1.74225Z"
      fill="#283C82"
    />,
    <path
      key={1}
      d="M13.902 4.3665H21.777V19.635H13.902V17.7262H20.1045V16.7722H13.902V15.5797H20.1045V14.625H13.902V13.4325H20.1045V12.4777H13.902V11.2852H20.1045V10.3305H13.902V9.138H20.1045V8.18325H13.902V6.99075H20.1045V6.036H13.902V4.3665ZM6.42976 8.58225C6.87976 8.556 7.33651 8.53725 7.78951 8.51325C8.10676 10.1235 8.43151 11.7315 8.77501 13.335C9.04426 11.679 9.34276 10.0282 9.63151 8.37525C10.1081 8.35816 10.5845 8.33316 11.0603 8.30025C10.521 10.6117 10.0485 12.9427 9.45976 15.2407C9.06151 15.4485 8.46526 15.231 7.99276 15.2647C7.67551 13.6852 7.30576 12.1147 7.02151 10.5292C6.74251 12.075 6.37951 13.5997 6.06001 15.1312C5.60201 15.1072 5.14226 15.0795 4.68076 15.048C4.28476 12.948 3.81976 10.8637 3.45001 8.75925C3.85801 8.7405 4.26826 8.72325 4.67626 8.709C4.92226 10.2285 5.20126 11.7412 5.41576 13.263C5.75176 11.703 6.09601 10.1422 6.42976 8.58225Z"
      fill="white"
    />,
  ],
  pdf: [
    <path
      key={0}
      d="M18.075 1.55402L22.248 5.90402V22.446H6.65924V22.5H22.3012V5.95877L18.075 1.55402Z"
      fill="#909090"
    />,
    <path
      key={1}
      d="M18.0233 1.5H6.60602V22.446H22.248V5.90475L18.0225 1.5"
      fill="#F4F4F4"
    />,
    <path
      key={2}
      d="M6.49123 2.625H1.69873V7.74525H16.7737V2.625H6.49123Z"
      fill="#7A7B7C"
    />,
    <path
      key={3}
      d="M16.854 7.65824H1.79626V2.53424H16.854V7.65824Z"
      fill="#DD2025"
    />,
    <path
      key={4}
      d="M6.78903 3.40052H5.80878V7.00052H6.57978V5.78627L6.75003 5.79602C6.91545 5.79317 7.07933 5.76354 7.23528 5.70827C7.372 5.66124 7.49779 5.587 7.60503 5.49002C7.71415 5.39763 7.80019 5.28103 7.85628 5.14952C7.9315 4.93089 7.95837 4.69854 7.93503 4.46852C7.93034 4.3042 7.90154 4.14146 7.84953 3.98552C7.80218 3.87295 7.73192 3.77147 7.64321 3.68754C7.5545 3.60361 7.44929 3.53906 7.33428 3.49802C7.23483 3.46202 7.13209 3.43589 7.02753 3.42002C6.94835 3.4078 6.86839 3.40128 6.78828 3.40052M6.64653 5.12102H6.57978V4.01102H6.72453C6.78841 4.00641 6.85252 4.01622 6.9121 4.03971C6.97169 4.06321 7.02523 4.0998 7.06878 4.14677C7.15902 4.26753 7.20722 4.41452 7.20603 4.56527C7.20603 4.74977 7.20603 4.91702 7.03953 5.03477C6.91958 5.10074 6.78304 5.13044 6.64653 5.12027M9.39978 3.39077C9.31653 3.39077 9.23553 3.39677 9.17853 3.39902L9.00003 3.40352H8.41503V7.00352H9.10353C9.36665 7.01073 9.62862 6.96613 9.87453 6.87227C10.0725 6.79376 10.2477 6.6672 10.3845 6.50402C10.5175 6.33936 10.613 6.14764 10.6643 5.94227C10.7232 5.70967 10.7519 5.47045 10.7498 5.23052C10.7643 4.94713 10.7424 4.66306 10.6845 4.38527C10.6296 4.18079 10.5268 3.99234 10.3845 3.83552C10.2729 3.70889 10.1363 3.60674 9.98328 3.53552C9.85186 3.4747 9.71362 3.42988 9.57153 3.40202C9.51502 3.39268 9.45779 3.38842 9.40053 3.38927M9.26403 6.34202H9.18903V4.04402H9.19878C9.3534 4.02623 9.50984 4.05413 9.64878 4.12427C9.75052 4.20551 9.83343 4.30787 9.89178 4.42427C9.95474 4.54677 9.99104 4.68122 9.99828 4.81877C10.005 4.98377 9.99828 5.11877 9.99828 5.23052C10.0013 5.35924 9.99305 5.48799 9.97353 5.61527C9.95041 5.74594 9.90768 5.87237 9.84678 5.99027C9.77784 6.09988 9.68471 6.19225 9.57453 6.26027C9.48199 6.32011 9.37242 6.34804 9.26253 6.33977M13.0725 3.40352H11.25V7.00352H12.021V5.57552H12.996V4.90652H12.021V4.07252H13.071V3.40352"
      fill="#464648"
    />,
    <path
      key={5}
      d="M16.3358 15.1913C16.3358 15.1913 18.7268 14.7578 18.7268 15.5745C18.7268 16.3913 17.2455 16.059 16.3358 15.1913ZM14.568 15.2535C14.1881 15.3374 13.8179 15.4603 13.4633 15.6203L13.7633 14.9453C14.0633 14.2703 14.3745 13.35 14.3745 13.35C14.7325 13.9525 15.149 14.5182 15.618 15.039C15.2643 15.0917 14.9138 15.1639 14.568 15.255V15.2535ZM13.6215 10.3785C13.6215 9.66676 13.8518 9.47251 14.031 9.47251C14.2103 9.47251 14.412 9.55876 14.4188 10.1768C14.3604 10.7982 14.2302 11.4108 14.031 12.0023C13.7581 11.5056 13.6169 10.9474 13.6208 10.3808L13.6215 10.3785ZM10.1348 18.2655C9.40126 17.8268 11.673 16.476 12.0848 16.4325C12.0825 16.4333 10.9028 18.7245 10.1348 18.2655ZM19.425 15.6713C19.4175 15.5963 19.35 14.766 17.8725 14.8013C17.2567 14.7913 16.6411 14.8347 16.0328 14.931C15.4435 14.3373 14.936 13.6677 14.5238 12.9398C14.7835 12.1893 14.9406 11.4073 14.991 10.6148C14.9693 9.71476 14.754 9.19876 14.064 9.20626C13.374 9.21376 13.2735 9.81751 13.3643 10.716C13.4532 11.3198 13.6208 11.9093 13.863 12.4695C13.863 12.4695 13.5443 13.4618 13.1228 14.4488C12.7013 15.4358 12.4133 15.9533 12.4133 15.9533C11.6803 16.1919 10.9903 16.5464 10.3695 17.0033C9.75151 17.5785 9.50026 18.0203 9.82576 18.462C10.1063 18.843 11.088 18.9293 11.9655 17.7795C12.4318 17.1857 12.8577 16.5613 13.2405 15.9105C13.2405 15.9105 14.5785 15.5438 14.9948 15.4433C15.411 15.3428 15.9143 15.2633 15.9143 15.2633C15.9143 15.2633 17.136 16.4925 18.3143 16.449C19.4925 16.4055 19.4355 15.7448 19.428 15.6728"
      fill="#DD2025"
    />,
    <path
      key={6}
      d="M17.9655 1.55774V5.96249H22.1903L17.9655 1.55774Z"
      fill="#909090"
    />,
    <path
      key={7}
      d="M18.0233 1.5V5.90475H22.248L18.0233 1.5Z"
      fill="#F4F4F4"
    />,
    <path
      key={8}
      d="M6.73123 3.34278H5.75098V6.94278H6.52498V5.72928L6.69598 5.73903C6.8614 5.73618 7.02528 5.70655 7.18123 5.65128C7.31794 5.60423 7.44372 5.52999 7.55098 5.43303C7.65928 5.34039 7.74453 5.22382 7.79998 5.09253C7.8752 4.8739 7.90207 4.64155 7.87873 4.41153C7.87404 4.24721 7.84523 4.08447 7.79323 3.92853C7.74588 3.81596 7.67562 3.71448 7.58691 3.63055C7.4982 3.54662 7.39299 3.48207 7.27798 3.44103C7.17808 3.40468 7.07482 3.3783 6.96973 3.36228C6.89055 3.35006 6.81059 3.34354 6.73048 3.34278M6.58873 5.06328H6.52198V3.95328H6.66748C6.73136 3.94867 6.79547 3.95848 6.85505 3.98197C6.91464 4.00547 6.96818 4.04206 7.01173 4.08903C7.10197 4.20979 7.15017 4.35678 7.14898 4.50753C7.14898 4.69203 7.14898 4.85928 6.98248 4.97703C6.86253 5.043 6.72599 5.0727 6.58948 5.06253M9.34198 3.33303C9.25873 3.33303 9.17773 3.33903 9.12073 3.34128L8.94448 3.34578H8.35948V6.94578H9.04798C9.3111 6.95299 9.57306 6.90839 9.81898 6.81453C10.0169 6.73602 10.1922 6.60946 10.329 6.44628C10.462 6.28162 10.5575 6.08991 10.6087 5.88453C10.6676 5.65193 10.6964 5.41271 10.6942 5.17278C10.7088 4.8894 10.6868 4.60532 10.629 4.32753C10.5741 4.12305 10.4712 3.9346 10.329 3.77778C10.2174 3.65115 10.0808 3.549 9.92773 3.47778C9.79631 3.41696 9.65807 3.37214 9.51598 3.34428C9.45947 3.33494 9.40224 3.33068 9.34498 3.33153M9.20848 6.28428H9.13348V3.98628H9.14323C9.29785 3.96849 9.45429 3.99639 9.59323 4.06653C9.69497 4.14777 9.77788 4.25013 9.83623 4.36653C9.89919 4.48903 9.93549 4.62348 9.94273 4.76103C9.94948 4.92603 9.94273 5.06103 9.94273 5.17278C9.94578 5.3015 9.9375 5.43025 9.91798 5.55753C9.89486 5.6882 9.85213 5.81463 9.79123 5.93253C9.72229 6.04214 9.62916 6.13451 9.51898 6.20253C9.42644 6.26238 9.31687 6.2903 9.20698 6.28203M13.0147 3.34578H11.1922V6.94578H11.9632V5.51778H12.9382V4.84878H11.9632V4.01478H13.0132V3.34578"
      fill="white"
    />,
  ],
  google: [
    <g key={1} clipPath="url(#clip0_108_6476)">
      <path
        d="M23.4392 12.2245C23.4392 11.2413 23.3594 10.5237 23.1868 9.77963H11.9588V14.2176H18.5494C18.4165 15.3205 17.699 16.9815 16.1045 18.0976L16.0821 18.2461L19.6322 20.9963L19.8782 21.0209C22.137 18.9347 23.4392 15.8653 23.4392 12.2245Z"
        fill="#4285F4"
      />
      <path
        d="M11.9588 23.9176C15.1876 23.9176 17.8982 22.8545 19.8781 21.0209L16.1045 18.0976C15.0946 18.8018 13.7393 19.2934 11.9588 19.2934C8.79637 19.2934 6.11231 17.2074 5.15551 14.324L5.01527 14.3359L1.32385 17.1927L1.27557 17.3269C3.24211 21.2334 7.28153 23.9176 11.9588 23.9176Z"
        fill="#34A853"
      />
      <path
        d="M5.15551 14.324C4.90305 13.5799 4.75695 12.7826 4.75695 11.9588C4.75695 11.1349 4.90305 10.3377 5.14223 9.5936L5.13554 9.43513L1.39786 6.53241L1.27557 6.59058C0.465069 8.21168 0 10.0321 0 11.9588C0 13.8855 0.465069 15.7058 1.27557 17.3269L5.15551 14.324Z"
        fill="#FBBC05"
      />
      <path
        d="M11.9588 4.62403C14.2043 4.62403 15.7191 5.59402 16.5828 6.40461L19.9578 3.10928C17.885 1.1826 15.1876 0 11.9588 0C7.28153 0 3.24211 2.68406 1.27557 6.59056L5.14223 9.59359C6.11231 6.7102 8.79637 4.62403 11.9588 4.62403Z"
        fill="#EB4335"
      />
    </g>,
    <defs key={2}>
      <clipPath id="clip0_108_6476">
        <rect width="23.4504" height="24" fill="white" />
      </clipPath>
    </defs>,
  ],
  tiktok: [
    <path
      key={1}
      d="M17.1765 8.66348C18.7198 9.77066 20.6105 10.4221 22.6525 10.4221V6.47863C22.2661 6.47871 21.8806 6.43827 21.5025 6.35788V9.46195C19.4606 9.46195 17.5702 8.81051 16.0265 7.70342V15.7509C16.0265 19.7766 12.7745 23.0399 8.76333 23.0399C7.26666 23.0399 5.87556 22.5858 4.71997 21.8071C6.03888 23.1604 7.87821 24 9.91309 24C13.9245 24 17.1766 20.7367 17.1766 16.7108V8.66348H17.1765ZM18.5951 4.68501C17.8064 3.82024 17.2885 2.70269 17.1765 1.46719V0.959976H16.0867C16.361 2.5303 17.2966 3.87189 18.5951 4.68501ZM7.25706 18.7178C6.81638 18.138 6.57824 17.4286 6.57931 16.6993C6.57931 14.858 8.06672 13.3651 9.90177 13.3651C10.2438 13.3651 10.5837 13.4176 10.9096 13.5214V9.48979C10.5287 9.4374 10.1443 9.41516 9.7601 9.42332V12.5613C9.43394 12.4575 9.09384 12.4048 8.75177 12.4052C6.91671 12.4052 5.42938 13.8979 5.42938 15.7394C5.42938 17.0414 6.17284 18.1687 7.25706 18.7178Z"
      fill="#FF004F"
    />,
    <path
      key={2}
      d="M16.0265 7.70334C17.5702 8.81043 19.4607 9.46186 21.5025 9.46186V6.35779C20.3628 6.11415 19.3538 5.51642 18.5951 4.68501C17.2966 3.8718 16.361 2.53021 16.0867 0.959976H13.2241V16.7106C13.2176 18.5468 11.7327 20.0336 9.90162 20.0336C8.82257 20.0336 7.86394 19.5174 7.25682 18.7178C6.17269 18.1687 5.42923 17.0413 5.42923 15.7394C5.42923 13.8981 6.91655 12.4052 8.75161 12.4052C9.1032 12.4052 9.44208 12.4602 9.75995 12.5614V9.4234C5.81921 9.50511 2.6499 12.7365 2.6499 16.7107C2.6499 18.6946 3.43913 20.4931 4.72006 21.8071C5.87565 22.5858 7.26675 23.04 8.76342 23.04C12.7747 23.04 16.0265 19.7766 16.0265 15.7509V7.70334H16.0265Z"
      fill="black"
    />,
    <path
      key={3}
      d="M21.5025 6.3578V5.51848C20.4748 5.52005 19.4672 5.23119 18.5952 4.68493C19.3671 5.53306 20.3835 6.11787 21.5025 6.3578ZM16.0867 0.959983C16.0605 0.809911 16.0404 0.658851 16.0265 0.507214V0H12.074V15.7508C12.0677 17.5868 10.5829 19.0736 8.75164 19.0736C8.214 19.0736 7.70638 18.9455 7.25685 18.7179C7.86397 19.5174 8.82259 20.0336 9.90164 20.0336C11.7326 20.0336 13.2177 18.5469 13.2241 16.7107V0.959983H16.0867ZM9.76014 9.42341V8.52989C9.42988 8.48459 9.09691 8.46186 8.76353 8.46202C4.75192 8.46194 1.5 11.7254 1.5 15.7508C1.5 18.2745 2.77806 20.4987 4.72017 21.807C3.43924 20.493 2.65001 18.6944 2.65001 16.7106C2.65001 12.7365 5.81924 9.50511 9.76014 9.42341Z"
      fill="#00F2EA"
    />,
  ],
  facebook: [
    <g key={1} clipPath="url(#clip0_2_17711)">
      <path
        d="M24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 17.9895 4.3882 22.954 10.125 23.8542V15.4688H7.07812V12H10.125V9.35625C10.125 6.34875 11.9166 4.6875 14.6576 4.6875C15.9701 4.6875 17.3438 4.92188 17.3438 4.92188V7.875H15.8306C14.34 7.875 13.875 8.80008 13.875 9.75V12H17.2031L16.6711 15.4688H13.875V23.8542C19.6118 22.954 24 17.9895 24 12Z"
        fill="#1877F2"
      />
      <path
        d="M16.6711 15.4688L17.2031 12H13.875V9.75C13.875 8.80102 14.34 7.875 15.8306 7.875H17.3438V4.92188C17.3438 4.92188 15.9705 4.6875 14.6576 4.6875C11.9166 4.6875 10.125 6.34875 10.125 9.35625V12H7.07812V15.4688H10.125V23.8542C11.3674 24.0486 12.6326 24.0486 13.875 23.8542V15.4688H16.6711Z"
        fill="white"
      />
    </g>,
    <defs key={2}>
      <clipPath id="clip0_2_17711">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>,
  ],
  zalo: [
    <rect key={1} width="24" height="24" fill="url(#pattern0)" />,
    <defs key={2}>
      <pattern
        id="pattern0"
        patternContentUnits="objectBoundingBox"
        width="1"
        height="1"
      >
        <use xlinkHref="#image0_108_6482" transform="scale(0.000488281)" />
      </pattern>
      <image
        id="image0_108_6482"
        width="2048"
        height="2048"
        xlinkHref={ZaloLogo}
      />
    </defs>,
  ],
};
