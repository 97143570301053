import HotJarScript from '@/components/script/HotJarScript';
import { addPathToLocalStorage } from '@/helpers/managePrevPath';
import useEnsureFillInfo from '@/hooks/useEnsureFillInfo';
import { CacheProvider } from '@emotion/react';
import { AxiosError } from 'axios';
import createEmotionCache from 'common/createEmotionCache';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { DefaultSeo, NextSeo } from 'next-seo';
import Head from 'next/head';
import { Router, useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import TagManager, { TagManagerArgs } from 'react-gtm-module';
import { Hydrate, QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import 'regenerator-runtime/runtime';
import GAScript from '../common/GAScript';
import GTMScript from '../common/GTMScript';
import { prevPathKey } from '../helpers/checkRouterPrevPath';
import usePreventOpenDevtool from '../hooks/usePreventOpenDevtool';
import { AppPropsWithLayout } from '../interfaces/components/layout.interface';
import { CustomThemeProvider } from '../lib/custom/customTheme';
import SocketsProvider from '../socket/socket-context';
import { AuthProvider } from '../state/auth/AuthContext';
import ModalProvider from '../state/modal-provider/ModalProvider';
import SnackBarProvider from '../state/snackbar-provider/SnackBarProvider';
import './globals.css';
import Greeting from './greeting';
dayjs.extend(utc);
dayjs.extend(timezone);

const clientSideEmotionCache = createEmotionCache();

function MyApp({
  Component,
  emotionCache = clientSideEmotionCache,
  pageProps: { dehydratedState, seo, ...pageProps },
}: AppPropsWithLayout) {
  const tagManagerArgs: TagManagerArgs = {
    gtmId: 'GTM-PKNQFMH',
  };
  // Use the layout defined at the page level, if available
  const router = useRouter();
  const getLayout = Component.getLayout || ((page) => page);
  const isProduction: boolean = process.env.ENV === 'production';
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
            retry(failureCount, error: any) {
              const err = error as AxiosError;

              if (err?.response?.status === 404) {
                router.replace('/404');
              }

              return false;
            },
          },
        },
      })
  );
  const [loading, setLoading] = useState(false);
  usePreventOpenDevtool();
  useEffect(() => {
    isProduction && TagManager.initialize(tagManagerArgs);
    addPathToLocalStorage(router.asPath);
  }, []);

  //show loading when navigating/loading components
  useEffect(() => {
    Router.events.on('routeChangeStart', (prevPath) => {
      setLoading(true);
      sessionStorage.setItem(prevPathKey, prevPath);
      addPathToLocalStorage(prevPath);
    });
    Router.events.on('routeChangeComplete', () => setLoading(false));
    Router.events.on('routeChangeError', () => setLoading(false));

    return () => {
      Router.events.off('routeChangeStart', () => setLoading(true));
      Router.events.off('routeChangeComplete', () => setLoading(false));
      Router.events.off('routeChangeError', () => setLoading(false));
    };
  }, []);

  useEnsureFillInfo(loading);

  return (
    <CacheProvider value={emotionCache}>
      <Head>
        <meta
          name="google-site-verification"
          content="guRRn24wnflx9AQhunmgLXi8BUXPbfbbrsCU1c8Esak"
        />
        {isProduction && <GTMScript />}
      </Head>
      <DefaultSeo
        title="Doccen.vn | Chứng chỉ CME, CPE - Khóa học & Trắc nghiệm y khoa"
        description="Tham dự hội thảo y khoa, nhận chứng chỉ đào tạo liên tục CME 48 tiết, khóa học chuyên môn miễn phí và kho trắc nghiệm y khoa đến từ Đại học hàng đầu."
      />
      {seo && <NextSeo {...seo} />}
      {isProduction && (
        <>
          <GAScript />
          <HotJarScript />
        </>
      )}
      <QueryClientProvider client={queryClient}>
        <Hydrate state={dehydratedState}>
          <ModalProvider>
            <SnackBarProvider>
              <AuthProvider>
                <SocketsProvider>
                  <CustomThemeProvider>
                    {loading ? (
                      <Greeting />
                    ) : (
                      <>{getLayout(<Component {...pageProps} />, pageProps)}</>
                    )}
                    <ReactQueryDevtools />
                  </CustomThemeProvider>
                </SocketsProvider>
              </AuthProvider>
            </SnackBarProvider>
          </ModalProvider>
        </Hydrate>
      </QueryClientProvider>
    </CacheProvider>
  );
}

export default MyApp;
