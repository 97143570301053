import { SUBSCRIPTIONS_LATEST_PLAN } from '@/constant/QueryKeyName';
import { ILastestSubscriptionPlanResponse } from '@/interfaces/subscription.interface';
import { getLatestSubscription } from 'apis/tms/subscription.api';
import { AxiosError } from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';

const useGetLatestSubscription = ({
  opt,
}: {
  opt?: Omit<
    UseQueryOptions<ILastestSubscriptionPlanResponse, AxiosError>,
    'queryKey' | 'queryFn'
  >;
}) => {
  const queryReturn = useQuery<ILastestSubscriptionPlanResponse, AxiosError>(
    [SUBSCRIPTIONS_LATEST_PLAN],
    getLatestSubscription,
    {
      ...opt,
      staleTime: 10 * 60 * 1000,
    }
  );

  return queryReturn;
};

export default useGetLatestSubscription;
