import UpgradeAccountButton from '@/components/buttons/UpgradeAccountButton';
import { ExpiredIllustration } from '@/constant/imgImport';
import { Dialog } from '@mui/material';
import { patchReminderSubscription } from 'apis/tms/subscription.api';
import { Button } from 'design-system/components/Button';
import { Icon } from 'design-system/components/Icon';
import { Typography } from 'design-system/components/Typography';
import Image from 'next/image';
import { useEffect } from 'react';
import { useMutation } from 'react-query';

interface IExpiredSubscriptionModal {
  open: boolean;
  toggle: () => void;
  packageName?: string;
  planDuration?: string;
  subscriptionId?: string;
}

const ExpiredSubscriptionModal = ({
  open,
  toggle,
  packageName,
  planDuration,
  subscriptionId,
}: IExpiredSubscriptionModal) => {
  const { mutate } = useMutation({
    mutationFn: patchReminderSubscription,
  });

  useEffect(() => {
    if (subscriptionId)
      mutate({ subscriptionId: subscriptionId, isReminded: true });
  }, [subscriptionId]);

  return (
    <Dialog open={open} fullWidth maxWidth="sm">
      <div className="flex justify-end p-6">
        <Icon
          iconName="close-symbol"
          onClick={toggle}
          className="cursor-pointer text-Text/Lightbackground/Secondary"
        />
      </div>
      <div className="flex flex-col items-center justify-center gap-6 p-6 pt-2">
        <Typography variant="heading1" className="text-center">
          Gói Doccen {packageName}{' '}
          <span className="text-Warning/Main">{planDuration}</span> đã hết hạn
        </Typography>

        <Typography
          variant="body2"
          className="text-center text-Text/Lightbackground/Secondary"
        >
          Vui lòng đăng ký thêm để tiếp tục sử dụng dịch vụ trên Doccen.
        </Typography>

        <Image
          width={250}
          height={200}
          src={ExpiredIllustration}
          alt="expired"
        />
        <div className="flex flex-col items-center gap-2">
          <UpgradeAccountButton ignoreMobile>
            Tiếp tục đăng ký
          </UpgradeAccountButton>
          <Button emphasis="low" size="medium" className="text-Warning/Main">
            Tôi đã hiểu
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default ExpiredSubscriptionModal;
