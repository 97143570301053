import { TypographyVariant } from './types';

export const VARIANTS = {
  heading1: 'text-2xl font-semibold leading-[140%] tracking-[-0.4px]',
  heading2: 'text-xl font-semibold leading-[130%] tracking-[-0.5px]',
  heading3: 'text-lg font-semibold leading-[130%] tracking-[-0.25px]',
  heading4: 'text-base font-medium leading-[120%] tracking-[-0.25px]',
  display1: 'text-[32px] font-medium leading-[120%] tracking-[-1.2px]',
  display2: 'text-2xl font-medium leading-[120%] tracking-[-0.4px]',
  'display2.5': 'text-xl font-medium leading-[120%] tracking-[-0.5px]',
  display3: 'text-base font-medium leading-[150%] tracking-[-0.1px]',
  body1: 'text-lg font-medium leading-[120%] tracking-[-0.4px]',
  body2: 'text-base font-medium leading-[140%] tracking-[-0.6px]',
  body3: 'text-sm font-medium leading-[140%] tracking-[-0.2px]',
  body4: 'text-xs font-medium leading-[140%] tracking-[-0.4px]',
  strong1: 'text-lg font-bold leading-[150%] tracking-[-0.2px]',
  strong2: 'text-base font-bold leading-[140%] tracking-[-0.1px]',
  strong3: 'text-sm font-bold leading-[140%]',
  strong4: 'text-xs font-bold leading-[140%] tracking-[-0.25px]',
  'link18-regular-underline':
    'text-lg font-normal underline leading-[32px] tracking-[-0.5px]',
  'link18-semibold-underline':
    'text-lg font-medium underline leading-[32px] tracking-[-0.5px]',
  'link18-regular': 'text-lg font-normal leading-[32px] tracking-[-0.5px]',
  'link18-semibold': 'text-lg font-medium leading-[32px] tracking-[-0.5px]',
  'link16-regular-underline':
    'text-base font-normal underline leading-[28px] tracking-[-0.4px]',
  'link16-semibold-underline':
    'text-base font-medium underline leading-[28px] tracking-[-0.1px]',
  'link16-regular': 'text-base font-normal leading-[28px] tracking-[-0.4px]',
  'link16-semibold': 'text-base font-medium leading-[28px] tracking-[-0.4px]',
  'link14-regular-underline':
    'text-sm font-normal underline leading-[22px] tracking-[-0.4px]',
  'link14-semibold-underline':
    'text-sm font-medium underline leading-[22px] tracking-[-0.4px]',
  'link14-regular': 'text-sm font-normal leading-[22px] tracking-[-0.4px]',
  'link14-semibold': 'text-sm font-medium leading-[22px] tracking-[-0.4px]',
  'link12-regular-underline':
    'text-xs font-normal underline leading-[16px] tracking-[-0.2px]',
  'link12-semibold-underline':
    'text-xs font-semibold underline leading-[16px] tracking-[-0.2px]',
  'link12-regular': 'text-xs font-normal leading-[16px] tracking-[-0.2px]',
  'link12-semibold': 'text-xs font-medium leading-[16px] tracking-[-0.2px]',
  button1: 'text-base font-medium leading-[16px] tracking-[-0.4px]',
  button2: 'text-sm font-medium leading-[16px] tracking-[-0.25px]',
  button3: 'text-xs font-medium leading-[16px] tracking-[-0.4px]',
  'input-label': 'text-xs font-medium leading-[130%] tracking-[-0.4px]',
  input: 'text-base font-normal leading-[120%] tracking-[-0.6px]',
  'helper-text': 'text-[13px] font-medium leading-[18px] tracking-[-0.4px]',
  'table-heading': 'text-sm font-semibold leading-[22px] tracking-[-0.4px]',
  'table-data': 'text-sm font-medium leading-[22px] tracking-[-0.5px]',
  metadata: 'text-xs font-normal leading-[16px] tracking-[-0.25px]',
  'tooltip-label': 'text-xs font-medium leading-[16px] tracking-[-0.4px]',
  overline1: 'text-sm font-medium leading-[120%] tracking-[-0.6px]',
  overline2: 'text-xs font-medium leading-[130%] tracking-[-0.4px]',
  label: 'text-sm font-medium leading-[18px] tracking-[-0.4px]',
  tab: 'text-[10px] font-medium leading-[150%] tracking-[-0.7px]',
  'clock-time': 'text-[6px] font-medium leading-[150%] tracking-[-0.2px]',
};

function getTypographyClass(variant: TypographyVariant) {
  return VARIANTS[variant];
}

export { getTypographyClass };
