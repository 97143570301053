export enum ESubscriptionPackage {
  Advanced = 'Advanced',
  Premium = 'Premium',
  Enterprise = 'Enterprise',
}

export enum ESubscriptionDuration {
  ThreeDays = 'ThreeDays',
  OneMonth = 'OneMonth',
  SixMonths = 'SixMonths',
  OneYear = 'OneYear',
}

export const hashSubscriptionDuration = {
  [ESubscriptionDuration.ThreeDays]: '3 ngày',
  [ESubscriptionDuration.OneMonth]: '1 tháng',
  [ESubscriptionDuration.SixMonths]: '6 tháng',
  [ESubscriptionDuration.OneYear]: '12 tháng',
};

export const reversedHashSubscriptionDuration = {
  '3 ngày': ESubscriptionDuration.ThreeDays,
  '1 tháng': ESubscriptionDuration.OneMonth,
  '6 tháng': ESubscriptionDuration.SixMonths,
  '12 tháng': ESubscriptionDuration.OneYear,
};

export enum EUserSubscriptionStatus {
  Active = 'Active',
  Expired = 'Expired',
  ExpiredWithReminder = 'ExpiredWithReminder',
}

export interface ISubscriptionHeading extends ISubscriptionMainContent {}

export interface ISubscriptionMainContent {
  highlightPlan: ESubscriptionPackage;
}

export interface ISubscriptionTableRow {
  benefit: React.ReactNode | string;
  tooltip?: string;
  highlightBackground: boolean;
  benefitEnableValues: ESubscriptionPackage[];
  highlightPlan: ESubscriptionPackage;
  lastRow: boolean;
}

export interface ISubscriptionPlansResponse {
  packages: {
    packageName: ESubscriptionPackage;
    plans: {
      durationName: string;
      planId: string;
      price: number;
    }[];
  }[];
}

export interface ILastestSubscriptionPlanResponse {
  subscription: {
    durationname: string;
    endDate: string;
    packageName: ESubscriptionPackage;
    startDate: string;
    status: EUserSubscriptionStatus;
    subscriptionId: string;
    subscriptionResources: string[];
  } | null;
}

export interface ISubscriptionPlanShortInformation {
  id: string;
  durationName: string;
  packageName: ESubscriptionPackage;
}

export interface ISubscriptionModalInformation {
  id: string;
  durationName: ESubscriptionDuration;
  packageName: ESubscriptionPackage;
  price: number;
}
