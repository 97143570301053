import { useMediaQuery } from '@mui/material';

export const useScreen = () => {
  const isDesktop = useMediaQuery('(min-width:1024px)');
  const isTablet = useMediaQuery('(max-width:1023px) and (min-width:768px)');
  const isMobile = useMediaQuery('(max-width:767px) and (min-width:320px)');

  return { isDesktop, isTablet, isMobile };
};

export const useDynamicScreen = ({
  maxWidth,
  minWidth,
}: {
  minWidth?: number;
  maxWidth?: number;
}) => {
  const isMatchMinWidth = useMediaQuery(`(min-width:${minWidth}px)`);
  const isMatchMaxWidth = useMediaQuery(`(max-width:${maxWidth}px)`);

  return {
    isMatchMaxWidth,
    isMatchMinWidth,
  };
};
