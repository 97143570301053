import { ICONS } from './icons';
import { SOCIAL_ICONS } from './socialIcons';
import { IconName, IconVariant, SocialIconName } from './types';

function getIconPath(iconName: IconName, variant: IconVariant) {
  return ICONS[iconName][variant] ?? null;
}

function getSocialIconPath(iconName: SocialIconName) {
  return SOCIAL_ICONS[iconName] ?? null;
}

function getGenericIconPath(
  iconName: IconName | SocialIconName,
  variant: IconVariant
) {
  if (iconName in SOCIAL_ICONS) {
    return getSocialIconPath(iconName as SocialIconName);
  } else {
    return getIconPath(iconName as IconName, variant);
  }
}

export { getGenericIconPath, getIconPath, getSocialIconPath };
