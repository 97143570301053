import { forwardRef } from 'react';
import { getIconPath } from './helper';
import { IconProps } from './types';

const Icon = forwardRef<HTMLSpanElement, IconProps>(function Icon(props, ref) {
  const {
    iconName,
    variant = 'outline',
    size = 24,
    className,
    viewBox = '0 0 24 24',
    ...restProps
  } = props;
  const path = getIconPath(iconName, variant);
  return (
    <span ref={ref} className={className}>
      <svg
        width={size}
        height={size}
        viewBox={viewBox}
        fill={variant === 'outline' ? 'none' : 'currentColor'}
        {...restProps}
      >
        {path}
      </svg>
    </span>
  );
});

export default Icon;
